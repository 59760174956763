import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

import Image from './image'
import Chart from '../../images/donatechart.inline.svg'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #f9f9f9;
  `

  const Title = styled.h2`
    font-size: 2.4em;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    margin-bottom: .5em;
  `

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rustbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const HeaderWrap = styled.div`
    padding: 2em 2em 3em;
  `

  const Content = styled.div`
    display: flex;
    flex-align: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  `

  const Left = styled.div`
    flex: 1;
    margin-right: 2em;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  `
  const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 2em 2em;

  @media (min-width: 1024px) {
    margin: 0 2em;
    width: 23%;
  }

  p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.25em;
    margin-top: .25em;
  }  `

  const Right = styled.div`
    flex: 1;

    @media (min-width: 1024px) {
      margin-left: 2em;
    }
  `

  const Amount = styled.div`
    font-family: "Roboto Slab", serif;
    font-size: 1.8em;
    letter-spacing: 1px;
    text-align: center;

    i {
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
    }

    @media (min-width: 1024px) {
      text-align: left;
    }
  `
  const SubAmount = styled.div`
    font-family: "Roboto", sans-serif;
    font-size: 1.3em;
    margin-bottom: 1em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 1024px) {
      text-align: left;
    }
  `

  const DonorNumber = styled.div`
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    font-family: "Roboto Slab", serif;
    font-size: 2em;
    letter-spacing: 1px;
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <BackgroundImage
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ borderRadius: "1px", backgroundColor: "rgb(243, 39, 53)", width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "left bottom"}}
              >

              <HeaderWrap>
                <Title>Join Our Campaign!</Title>
                <Content>
                  <Left>
                    <Image />
                  </Left>
                  <Center>
                    <Chart />
                    <p><b>85%</b> Funded</p>
                  </Center>
                  <Right>
                    <Amount>
                      $<i>51,462</i>
                    </Amount>
                    <SubAmount>
                      <i>Donated</i>
                    </SubAmount>
                    <Amount>
                      $<i>60,000</i>
                    </Amount>
                    <SubAmount>
                      <i>Goal</i>
                    </SubAmount>
                    <DonorNumber>
                      459
                    </DonorNumber>
                    <SubAmount>
                      <i>Donors</i>
                    </SubAmount>
                  </Right>
                </Content>
              </HeaderWrap>
            </BackgroundImage>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
