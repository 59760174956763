import React from "react"
import styled from '@emotion/styled'
import Donate from './donate'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #f9f9f9;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.12em;
      margin-bottom: 1.25em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      color: #545454;
    }
  `

  const Title = styled.h2`
    font-size: 1.8em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .5em;
    }
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <Title>About Our Campaign</Title>
            <p>For nearly 30 years, the Fairview Farmers Market has been a beloved East Central Ohio institution, a place where we come together as a community, connect with the people that grow our food, and celebrate Ohio's agricultural heritage.</p>
            <p>At this critical time, however, the future of the Fairview Farmers Market is at risk.</p>
            <p>These are unprecedented times, and the need for access to nutrient dense, fresh and affordable food for all Ohioans has never been more important.</p>
            <p>Please make your gift to Fairview Farmers Market, a 501c3 non-profit organization, at any level so that we can continue ensuring that people have access to healthy, local, produce, and farmers, ranchers and food producers have income.</p>
            <Donate />
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
