import React from "react"
import styled from '@emotion/styled'

const Donate = () => {

  const Title = styled.h2`
    margin-top: 2em;
    font-size: 1.4em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;
    display: flex;
    flex-align: row;
    align-items: center;

    @media (min-width: 1024px) {
      font-size: 1.7em;
      margin-bottom: .5em;
    }

    b {
      border-radius: 50%;
      background-color: #F32B38;
      color: #f9f9f9;
      font-weight: 600;
      padding: .35em .75em;
      margin-right: 1em;
    }
  `

  const AmountForm = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0 3em;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 900px) {
      flex-direction: row;
    }

    li {
      color: #AAAAAA;
      /* display: block; */
      position: relative;
      /* float: left; */
      /* width: 100%; */
      /* height: 100px; */
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
    }

    li input[type=radio] {
      position: absolute;
      visibility: hidden;
    }

    li label {
      /* display: block;
      position: relative; */
      font-weight: 300;
      font-size: 1.25em;
      padding: 1em 2em;
      /* margin: 10px auto; */
      /* height: 30px; */
      z-index: 9;
      cursor: pointer;
      -webkit-transition: all 0.25s linear;
    }

    li:hover label {
      color: #545454;
    }

    li .check {
      display: block;
      position: absolute;
      border: 5px solid #AAAAAA;
      border-radius: 100%;
      height: 26px;
      width: 26px;
      /* top: 30px; */
      /* left: 20px; */
      z-index: 5;
      transition: border .25s linear;
      -webkit-transition: border .25s linear;
    }

    li:hover .check {
      border: 5px solid #545454;
    }

    li .check::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 10px;
      width: 10px;
      top: 3px;
      left: 3px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }

    input[type=radio]:checked ~ .check {
      border: 5px solid #0C4E3F;
    }

    input[type=radio]:checked ~ .check::before{
      background: #0C4E3F;
    }

    input[type=radio]:checked ~ label{
      color: #0C4E3F;
    }

  `

  const InfoForm = styled.ul`
    list-style: none;
    margin: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 900px) {
      flex-direction: row;
      padding: 0 2em;
    }

    li {
      width: calc(100% - 2em);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 1em 1em .75em;

      @media (min-width: 900px) {
        width: calc(50% - 2em);
      }
    }

    input {
      border-radius: 2px;
      color: #545454;
    }

    input[type=text] {
      padding: 12px 20px;
      flex: 1;
      outline: none;
      border: 1px solid #AAAAAA;
      transition: border .3s linear;

      :focus {
        border: 1px solid #031D5E;
        transition: border .3s linear;
      }
    }

  `

  const Submit = styled.button`

    margin-top: 3em;
    font-size: 18px;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    border-radius: 2px;
    background-color: #F32B38;
    color: #f9f9f9;
    padding: 10px 20px;
    margin-bottom: 2em;

  `

  return (
      <div style={{width: "100%"}}>
        <Title><b>1</b> Your Donation</Title>
        <AmountForm>
          <li><input type="radio" id="20" name="amount" value="20" /><label for="20">$20</label><div className="check"><div className="inside"></div></div></li>
          <li><input type="radio" id="50" name="amount" value="50" /><label for="50">$50</label><div className="check"><div className="inside"></div></div></li>
          <li><input type="radio" id="100" name="amount" value="100" /><label for="100">$100</label><div className="check"><div className="inside"></div></div></li>
          <li><input type="radio" id="250" name="amount" value="250" /><label for="250">$250</label><div className="check"><div className="inside"></div></div></li>
          <li><input type="radio" id="500" name="amount" value="500" /><label for="500">$500</label><div className="check"><div className="inside"></div></div></li>
          <li><input type="radio" id="1000" name="amount" value="1000" /><label for="1000">$1000</label><div className="check"><div className="inside"></div></div></li>
        </AmountForm>
        <Title><b>2</b> Your Information</Title>
        <InfoForm>
          <li><label for="firstname"></label><input type="text" id="firstname" name="info" placeholder="First Name" /></li>
          <li><label for="lastname"></label><input type="text" id="lastname" name="info" placeholder="Last Name" /></li>
          <li><label for="email"></label><input type="text" id="email" name="info" placeholder="Email Address" /></li>
          <li><label for="phone"></label><input type="text" id="phone" name="info" placeholder="Phone Number" /></li>
          <li><label for="street"></label><input type="text" id="street" name="info" placeholder="Street Address" /></li>
          <li><label for="city"></label><input type="text" id="city" name="info" placeholder="City" /></li>
          <li><label for="state"></label><input type="text" id="state" name="info" placeholder="State" /></li>
          <li><label for="zip"></label><input type="text" id="zip" name="info" placeholder="Zipcode" /></li>
        </InfoForm>
        <Submit>Continue</Submit>
      </div>
  )
}

export default Donate
